<!-- 通用的轮播图组件 -->

<!-- 
    依赖安装:npm install vue-awesome-swiper@3.1.3 -S 
    值得注意的是：目前 vue-awesome-swiper@3.1.3 版本最为稳定，其他版本可能不太稳定，建议优先使用 vue-awesome-swiper@3.1.3
 -->
<template>
  <div class="common_swiper">
    <swiper style="width: 100%;height: 100%;" :options="swiperOption">
      <swiper-slide v-for="(item, index) in imgArr" :key="index" style="display: flex; align-items: center;justify-content:center">
        <img :src="item" preview height="100%" />
      </swiper-slide>
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
    </swiper>
  </div>
</template>

<script>
import swiper from "@/mixin/swiper";
export default {
  mixins: [swiper],
  name: "",
  components: {},
  props: ["imgArr"],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.common_swiper {
  width: 100%;
  height: 100%;
}
</style>
