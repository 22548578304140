// 通用轮播图的混入文件
let comSwiper = {
  data() {
    return {
      swiperOption: {
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true //轮播按钮支持点击
        },
        //自动播放
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        //循环
        loop: false
      }
    };
  }
};

export default comSwiper;
