//教案PDF
<template>
  <div class="pdf_download">
    <!-- 右边的PDF展示区域展示 -->
    <div v-if="!showLoading" class="content" id="iframe_pdf" @mousemove="showMenu = true" @mouseleave="showMenu = false">
      <pdf
        id="pdf_show_content"
        style="height: 100%; overflow-y: scroll;"
        :page="pageNum"
        :src="pdfUrl"
        @progress="loadedRatio = $event"
        @num-pages="pageTotalNum = $event"
      ></pdf>

      <!-- 左右按钮 -->
      <button
        type="button"
        v-show="showMenu && currIndex != 0"
        :disabled="currIndex == 0"
        class="el-carousel__arrow el-carousel__arrow--left animated fadeIn"
        :class="{ dis_cursore: currIndex == 0 }"
        @click.stop="prePage"
      >
        <i class="el-icon-arrow-left"></i>
      </button>

      <button
        type="button"
        v-show="showMenu && currIndex != pageTotalNum - 1"
        :class="{ dis_cursore: currIndex == pageTotalNum - 1 }"
        class="el-carousel__arrow el-carousel__arrow--right animated fadeIn"
        @click.stop="nextPage"
      >
        <i class="el-icon-arrow-right"></i>
      </button>

      <!-- 放大缩小的按钮 -->
      <!-- <div class="bom_scale iconfont" :class="[isScale ? 'cloud-suoxiao' : 'cloud-icon--']" @click.stop="doScaleAction"></div> -->
    </div>

    <!-- 加载框 -->
    <Loading v-if="showLoading"></Loading>
  </div>
</template>
<script>
import pdf from "vue-pdf";
import screen from "@/mixin/screen";
export default {
  name: "PdfAndDown",
  mixins: [screen],
  data() {
    return {
      showLoading: true,
      //初始状态激活的幻灯片的索引，从 0 开始
      currPage: 0,
      pageNum: 1,
      pageTotalNum: 1, // 总页数
      loadedRatio: 0, // 当前页面的加载进度，范围是0-1 ，等于1的时候代表当前页已经完全加载完成了
      //选中的页码
      currIndex: 0,
      //是否显示左右菜单
      showMenu: false,
      //放大或者缩小的标识
      isScale: false,
      //下载的文件名称
      fileName: ""
    };
  },
  props: ["pdfUrl"],
  created() {
    this.httpData();
  },
  mounted() {
    //监听全屏事件
    window.onresize = () => {
      if (this.isFullscreen() == null) {
        //缩小状态
        this.isScale = false;
      } else {
        //放大状态
        this.isScale = true;
      }
    };
  },
  methods: {
    //获取列表数据
    async httpData() {
      let res = await this.getNumPages();
      this.pageTotalNum = res;
      this.showLoading = false;
    },

    //计算总的页码数量
    getNumPages() {
      return new Promise((resolve, reject) => {
        let loadingTask = pdf.createLoadingTask(this.pdfUrl);
        loadingTask.promise
          .then(pdf => {
            resolve(pdf.numPages);
          })
          .catch(err => {
            console.error("pdf 加载失败", err);
          });
      });
    },

    // 上一页
    prePage() {
      if (this.currIndex > 0) {
        this.currIndex--;
        let page = this.pageNum;
        page = page > 1 ? page - 1 : this.pageTotalNum;
        this.pageNum = page;
        //翻页时候回到顶部
        this.toTop();
      }
    },

    // 下一页
    nextPage() {
      if (this.currIndex < this.pageTotalNum - 1) {
        this.currIndex++;
        let page = this.pageNum;
        page = page < this.pageTotalNum ? page + 1 : 1;
        this.pageNum = page;
        //翻页时候回到顶部
        this.toTop();
      }
    },

    // 页面回到顶部
    toTop() {
      document.getElementById("pdf_show_content").scrollTop = 0;
    },

    //全屏的方法
    showFullAction() {
      let iframe = document.getElementById("iframe_pdf");
      this.requestFullScreen(iframe);
    },

    /**
     * 点击全屏和缩小的操作
     */
    doScaleAction() {
      if (this.isScale) {
        //缩小
        this.exitFullscreen();
      } else {
        //放大
        this.showFullAction();
      }
      this.isScale = !this.isScale;
    },

    //下载文件
    dowloadFile() {
      fetch(this.pdfUrl)
        .then(async res => await res.blob())
        .then(blob => {
          // 创建隐藏的可下载链接
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = URL.createObjectURL(blob);
          // 保存下来的文件名
          a.download = this.fileName;
          document.body.appendChild(a);
          a.click();
          // 移除元素
          document.body.removeChild(a);
        });
    }
  },
  components: {
    pdf
  }
};
</script>
<style lang="scss" scoped>
.pdf_download {
  width: 100%;
  height: 100vh;
  position: relative;
  // 右边的展示PPT列表部分
  .content {
    width: 100%;
    height: 100%;
    position: relative;
    .el-carousel__arrow {
      background: rgba($color: $common_bg, $alpha: 0.4);
    }

    .dis_cursore {
      cursor: not-allowed;
    }
  }
}
</style>
