//赛事中心-开始学习界面
<template>
  <div class="competition_study">
    <!-- 顶部的返回以及导航条部分 -->
    <div class="top">
      <!-- 返回 -->
      <div @click.stop="goBack" class="back iconfont cloud-fanhui"></div>

      <!-- 占位 -->
      <div class="placeholder"></div>

      <!-- 全屏的按钮 -->
      <div
        v-if="[`1`, `2`, `3`, `4`].includes(type)"
        class="bom_scale iconfont"
        :title="isScale ? '退出全屏' : '全屏'"
        :class="[isScale ? 'cloud-suoxiao' : 'cloud-icon--']"
        @click.stop="doScaleAction"
      ></div>

      <!-- 下载按钮 -->
      <div title="下载" v-if="[`1`, `2`, `3`, `4`, `7`].includes(type)" class="iconfont cloud-xiazai2 download" @click.stop="dowloadFile"></div>

      <!-- 当前小节文字 -->
      <!-- <div class="curr_name">当前小节</div> -->

      <!-- 导航条 -->
      <!-- <div class="top_nav">
        <div
          class=""
          :class="['item_nav', currPos == index ? 'item_nav_select' : 'item_nav_normal']"
          v-for="(item, index) in navArr"
          :key="index"
          @click.stop="doTopNavClick(item, index)"
          :title="item.title"
        >
          {{ index + 1 }}
        </div>
      </div> -->

      <!-- 右侧的未登录和已经登录显示的状态 -->
      <div style="margin-right: 40px; font-size: 18px; font-weight: bold;">
        <!-- 未登录 -->
        <div v-if="!this.$store.state.user.loginState" class="course_style" @click="doLoginClick">登&nbsp;&nbsp;录</div>

        <!-- 已登录显示头像 -->
        <img v-if="this.$store.state.user.loginState" class="img_head" :src="this.$store.state.user.userInfo.user_img" width="32" height="32" />
      </div>
    </div>

    <!-- 内容部分 -->
    <div id="iframe_pdf_tp" class="bom">
      <!--  type==4 pdf展示加下载 -->
      <PdfAndDown :pdfUrl="url" v-if="[`1`, `2`, `3`, `4`].includes(type)"></PdfAndDown>

      <!-- type==5 视频播放 -->
      <VideoPlay :videoUrl="url" v-if="type == 5"></VideoPlay>

      <!-- 资源下载中心 -->
      <ResourceDown :downUrl="url" v-if="type == 7"></ResourceDown>

      <!-- 图片展示的组件 -->
      <CommonSwiper v-if="type == 6" :imgArr="imgArr"></CommonSwiper>
    </div>

    <!-- 登录页的弹框 -->
    <LoginPage @close="closeLoginDialog" :showDialog="showLoginPageDialog" @doLoginCommit="doLoginCommit" />
  </div>
</template>
<script>
import screen from "@/mixin/screen";

//登录页弹框
import LoginPage from "@/views/dialog/LoginPage";

//引入pdf展示和下载的组件
import PdfAndDown from "@/views/competitionCenter/components/PdfAndDown.vue";

//视频播放组件
import VideoPlay from "@/views/competitionCenter/components/VideoPlay.vue";

//资源下载中心
import ResourceDown from "@/views/competitionCenter/components/ResourceDown.vue";

//图片展示的组件
import CommonSwiper from "@/components/CommonSwiper.vue";

export default {
  mixins: [screen],
  data() {
    return {
      //顶部导航条的数据,需要根据不同课程来动态传递
      navArr: [],
      //用户头像
      //是否显示登录框
      showLoginPageDialog: false,
      //资源地址分别对应PDF  PPT 视频 资源
      url: "",
      //需要暂时的组件类型
      type: -1,
      //放大或者缩小的标识
      isScale: false,
      //下载的文件名称
      fileName: "",
      //图片组件需要传递的数组
      imgArr: []
    };
  },

  created() {
    this.initParams();
  },

  mounted() {
    //禁用右键相关功能
    this.prohibit();
    //监听全屏事件
    window.onresize = () => {
      if (this.isFullscreen() == null) {
        //缩小状态
        this.isScale = false;
      } else {
        //放大状态
        this.isScale = true;
      }
    };
  },

  methods: {
    initParams() {
      let chosseLessonItem = JSON.parse(sessionStorage.getItem("chosseLessonItem"));
      let info = chosseLessonItem.info;
      this.fileName = chosseLessonItem.eventTypeName;

      //取出第一个元素
      let obj = info[0];
      //类型对应1-pdf 3-pdf  2-ppt  4-pdf并且下载  5-视频  6-图片  7-对应压缩文件下载
      this.type = chosseLessonItem.type;
      this.url = obj.url;
      if (this.type == 6) {
        this.imgArr.push(this.url);
      }

      //初始化navArr的值
      if (!this.isEmpty(info)) {
        info.forEach(item => {
          let obj = new Object();
          if ([1, 3, 4].includes(this.type)) {
            obj.name = "PDF";
          } else if (this.type == 2) {
            obj.name = "PPT";
          } else if (this.type == 5) {
            obj.name = "视频";
          } else if (this.type == 6) {
            obj.name = "图片";
          } else if (this.type == 7) {
            obj.name = "资源下载";
          }
          this.navArr.push(obj);
        });
      }
    },

    //返回
    goBack() {
      this.$router.back(-1);
    },

    //顶部导航的点击事件
    doTopNavClick(item, pos) {
      if (this.currPos != pos) {
        this.currPos = pos;
      }
    },

    //弹出登录框
    doLoginClick() {
      this.showLoginPageDialog = true;
    },

    //关闭登录按钮
    closeLoginDialog() {
      this.showLoginPageDialog = false;
    },

    //登录提交
    doLoginCommit() {
      this.$store.commit("user/setLoginState", true);
      this.showLoginPageDialog = false;
    },

    //全屏的方法
    showFullAction() {
      let iframe = document.getElementById("iframe_pdf_tp");
      this.requestFullScreen(iframe);
    },

    /**
     * 点击全屏和缩小的操作
     */
    doScaleAction() {
      if (this.isScale) {
        //缩小
        this.exitFullscreen();
      } else {
        //放大
        this.showFullAction();
      }
      this.isScale = !this.isScale;
    },

    //下载文件
    dowloadFile() {
      //判断用户是否登录,如果没登录提示登录后方可进行下载
      if (!this.$store.state.user.loginState) {
        this.showWarnMsg("登录后方可进行下载操作");
        return;
      }

      fetch(this.url)
        .then(async res => await res.blob())
        .then(blob => {
          // 创建隐藏的可下载链接
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = URL.createObjectURL(blob);
          // 保存下来的文件名
          a.download = this.fileName;
          document.body.appendChild(a);
          a.click();
          // 移除元素
          document.body.removeChild(a);
        });
    }
  },
  computed: {},
  components: {
    LoginPage,
    PdfAndDown,
    VideoPlay,
    ResourceDown,
    CommonSwiper
  }
};
</script>
<style lang="scss" scoped>
.competition_study {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  .top {
    display: flex;
    height: 70px;
    min-height: 70px;
    align-items: center;
    background: #302f35;
    color: white;
    .back {
      color: white;
      font-size: 22px;
      font-weight: bold;
      cursor: pointer;
      margin-left: 16px;
    }

    .placeholder {
      flex: 1;
    }

    //当前小节名称
    .curr_name {
      font-size: 14px;
    }

    .img_head {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
      border: 1px solid white;
    }

    //导航条
    .top_nav {
      margin: 0 16px;
      display: flex;
      margin-right: 40px;
      .item_nav {
        cursor: pointer;
        margin-left: 8px;
        margin-right: 8px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        border: 2px solid white;
      }

      //顶部导航条选中的样式
      .item_nav_select {
        background: #fdd420;
      }

      //顶部导航条未选中样式
      .item_nav_normal {
        background: #494b56;
      }
    }
    .bom_scale {
      cursor: pointer;
      color: #fdd420;
      padding: 4px;
      margin-right: 40px;
      font-size: 20px;
      border-radius: 2px;
    }

    .course_style {
      cursor: pointer;
    }

    .download {
      cursor: pointer;
      margin-right: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fdd420;
      font-size: 28px;
      cursor: pointer;
    }

    //头像
    .img_head {
      margin-right: 26px;
      cursor: pointer;
      border-radius: 50%;
    }
  }
  .bom {
    height: calc(100vh - 70px);
  }
}
.competition_study ::-webkit-scrollbar {
  display: none;
}
</style>
