<!-- 视频播放组件 -->
<template>
  <div class="play_video">
    <div class="content">
      <!-- 引入视频播放组件 -->
      <video-player class="custom-video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
    </div>
  </div>
</template>
<script>
//引入混入文件
import video from "@/mixin/video";
export default {
  name: "playVideo",
  mixins: [video],
  data() {
    return {
      //加载框
      showLoading: true
    };
  },
  created() {
    //初始化播放器对象
    this.initPlayer();
  },
  mounted() {},
  methods: {
    //配置播放器对象
    initPlayer() {
      this.playerOptions.sources[0].src = this.videoUrl;
      //设置封面
      //this.playerOptions.poster = this.videoList[0].videoCr;
      //动态设置播放器的宽高比
      this.playerOptions.aspectRatio = "16:9";
    }
  },
  computed: {},
  components: {},
  props: ["videoUrl"]
};
</script>
<style lang="scss" scoped>
.play_video {
  width: 100%;
  height: calc(100vh - 70px);
  background: black;
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .custom-video-player {
      width: 1280px; /* 设置视频播放器的宽度 */
      // height: 500px; /* 设置视频播放器的高度 */
    }

    ::v-deep .custom-video-player .vjs-big-play-button {
      background: rgba($color: #fdd420, $alpha: 0.5);
      border: none;
      border-radius: 50%;
      width: 70px;
    }
  }
}
</style>
