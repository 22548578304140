<!--  资源下载组件 -->
<template>
  <div class="resource_down">
    <div class="down_info">
      <!-- 下载按钮 -->
      <i class="iconfont cloud-xiazai3  i_down"></i>
      <!-- 下载的文件名称 -->
      <font>{{ fileName }}</font>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["downUrl"],
  data() {
    return {
      fileName: ""
    };
  },
  created() {
    this.parseInfoByUrl();
  },
  mounted() {},
  methods: {
    //根据下载的Url解析下载的文件名
    parseInfoByUrl() {
      //http://codefrog.zdwkj.com/croak_test/croak_cloud_test/api/assets/sszx/程序蛙硬件课编程软件.rar
      let index = this.downUrl.lastIndexOf("/");
      this.fileName = this.downUrl.slice(index + 1);
      console.log(`文件名称是:`, this.fileName);
    }
  }
};
</script>

<style lang="scss" scoped>
.resource_down {
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;

  .down_info {
    cursor: pointer;
    display: flex;
    height: 48px;
    padding: 6px 12px;
    font-size: 16px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    border: 4px;
    background: rgba($color: black, $alpha: 1);
    color: white;
  }
  .i_down {
    font-size: 32px;
    margin-right: 14px;
  }
}
</style>
